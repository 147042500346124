import { NavElement } from './styles';
import clsx from 'clsx';

const Nav = ({ items }) => {
    return (
        <NavElement>
            <ul>
                {items.map(({ name, isActive, ...rest }) => (
                    <li key={name}>
                        <a {...rest} className={clsx({ active: isActive })}>
                            {name}
                        </a>
                    </li>
                ))}
            </ul>
        </NavElement>
    );
};

export default Nav;
