import React, { useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from './components/Header';
import Footer from './components/Footer';
import FullPageProvider from './Providers/FullPageProvider';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ContactSection from './components/ContactSection';
import RoomSection from './components/RoomSection';
import ReactGA from 'react-ga';
const TRACKING_ID = process.env.TRACKING_ID;

ReactGA.initialize(TRACKING_ID);

const App = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    return (
        <>
            <FullPageProvider
                fpProps={{
                    fixedElements: '#pageHeader',
                    controlArrows: false,
                    onLeave: (origin, destination, direction) => setActiveSlideIndex(destination.index),
                }}
            >
                <ReactFullpage.Wrapper>
                    <Header activeIndex={activeSlideIndex} />
                    <HeroSection />
                    <RoomSection />
                    <AboutSection activeIndex={activeSlideIndex} />
                    <ContactSection />
                    <div className='section fp-auto-height'>
                        <Footer />
                    </div>
                </ReactFullpage.Wrapper>
            </FullPageProvider>
        </>
    );
};

export default App;
