import { useState, useEffect } from 'react';
import { ReactComponent as LightSvg } from '../../icons/light.svg';
import { ReactComponent as MoonSvg } from '../../icons/moon.svg';
import { Button } from './styles';

const ThemeToggle = () => {
    const [theme, setTheme] = useState('dark');

    const handleClick = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    return <Button onClick={handleClick}>{theme === 'light' ? <LightSvg /> : <MoonSvg />}</Button>;
};

export default ThemeToggle;
