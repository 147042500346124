import styled from 'styled-components';

export const HeaderWrapper = styled.header`
    position: fixed;
    left: 0;
    right: 0;
    top: 20px;
    z-index: 1000;
`;

export const HeaderContent = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
`;

export const LogoButton = styled.a`
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    margin-right: 40px;
    width: 60px;
    height: 60px;
`;

export const HeaderLeft = styled.div``;

export const HeaderRight = styled.div``;
