import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';
import { ReactComponent as AddressIcon } from '../../icons/address.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';

const ContactSection = () => {
    const { t } = useTranslation();
    return (
        <Wrapper className='section'>
            <div className='content'>
                <h2 className='section-title'>{t('common.contact')}</h2>
                <ul>
                    <li>
                        <AddressIcon /> <span>{t('contact.address')}</span>
                    </li>
                    <li>
                        <PhoneIcon />
                        <span>
                            <a href='tel:+374 55 11 11 06'>+374 55 11 11 06</a> |
                            <a href='tel:+374 55 11 11 06'>+374 012 22 33 33</a>
                        </span>
                    </li>
                    <li>
                        <EmailIcon />
                        <span>
                            <a href='mailto:macaohotelyerevan@gmail.com'>macaohotelyerevan@gmail.com</a>
                        </span>
                    </li>
                </ul>
            </div>
        </Wrapper>
    );
};

export default ContactSection;
