import { HeaderWrapper, HeaderContent, HeaderLeft, HeaderRight, LogoButton } from './styles';
import ThemeToggle from '../ThemeToggle';
import LanguageToggle from '../LanguageToggle';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import Nav from '../Nav';
import { useFullPageContext } from '../../Providers/FullPageProvider';
import { useTranslation } from 'react-i18next';

const Header = ({ activeIndex }) => {
    const { fullpageApi } = useFullPageContext();
    const { t } = useTranslation();

    const navItems = [
        {
            name: t('navigation.home'),
            onClick: () => fullpageApi.moveTo(1),
            isActive: activeIndex === 0,
        },
        {
            name: t('navigation.rooms'),
            onClick: () => fullpageApi.moveTo(2),
            isActive: activeIndex === 1,
        },
        {
            name: t('navigation.about'),
            onClick: () => fullpageApi.moveTo(3),
            isActive: activeIndex === 2,
        },
        {
            name: t('navigation.contact'),
            onClick: () => fullpageApi.moveTo(4),
            isActive: activeIndex === 3,
        },
    ];

    return (
        <HeaderWrapper id='pageHeader'>
            <div className='container container-fluid'>
                <HeaderContent>
                    <HeaderLeft>
                        <div className='flex'>
                            <LogoButton onClick={() => fullpageApi.moveTo(1)}>
                                <Logo width={60} />
                            </LogoButton>
                            <Nav items={navItems} />
                        </div>
                    </HeaderLeft>

                    <HeaderRight>
                        <div className='flex'>
                            <ThemeToggle />
                            <LanguageToggle />
                        </div>
                    </HeaderRight>
                </HeaderContent>
            </div>
        </HeaderWrapper>
    );
};

export default Header;
