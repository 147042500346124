import styled from 'styled-components';

export const ToggleButton = styled.button`
    opacity: 0.7;
    position: relative;
    border-radius: 5px;
    width: 40px;
    height: 32px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--color-text);
    position: relative;

    &:hover {
        ul {
            visibility: visible;
            opacity: 1;
        }
    }
    > svg {
        width: 40px;
    }

    > ul {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        list-style: none;
        top: 100%;
        background-color: var(--color-background);
        border-radius: 10px;
        margin-top: 10px;
        padding-left: 0;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        > li {
            a {
                padding: 5px 15px;
                display: block;
            }
            &:hover {
                background-color: var(--color-gray-100);
            }
        }
    }
`;
