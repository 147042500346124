import styled from 'styled-components';

export const Wrapper = styled.section`
    position: relative;
    .slide {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .fp-slidesNav {
        display: none;
    }

    .fp-slidesNav ul {
        text-align: center;
        position: relative;
    }

    .section-overlay {
        position: absolute;
        inset: 0;
        background-color: var(--color-muted-background);
        z-index: 8;
        opacity: 0.7;
    }
`;

export const Content = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 70%;
    margin: auto;
    z-index: 11;
    p {
        font-size: 17px;
    }

    @media only screen and (max-width: 767px) {
        width: 90%;
        p {
            font-size: 13px;
        }
    }
`;
