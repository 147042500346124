import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, ru, hy } from './translations';
import './styles/index.css';

const lng = localStorage.getItem('locale') || 'hy';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        ru: { translation: ru },
        hy: { translation: hy },
        lng: lng,
        fallbackLng: lng,
    },
    lng: lng,
    fallbackLng: lng,
    interpolation: {
        escapeValue: false,
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
