import styled from 'styled-components';

export const Button = styled.button`
    opacity: 0.7;
    position: relative;
    border-radius: 5px;
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 5px;
`;
