/* eslint-disable jsx-a11y/anchor-is-valid */
import { ToggleButton } from './styles';
import { ReactComponent as GlobSvg } from '../../icons/globe-grid.svg';
import { useTranslation } from 'react-i18next';

const LanguageToggle = () => {
    const { i18n } = useTranslation();

    const handleClickLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('locale', lng);
    };
    return (
        <ToggleButton>
            <GlobSvg />
            <ul>
                <li>
                    <a onClick={() => handleClickLanguage('en')}>En</a>
                </li>
                <li>
                    <a onClick={() => handleClickLanguage('hy')}>Հայ</a>
                </li>
                <li>
                    <a onClick={() => handleClickLanguage('ru')}>Рус</a>
                </li>
            </ul>
        </ToggleButton>
    );
};

export default LanguageToggle;
