import styled from 'styled-components';

export const FooterWrapper = styled.footer`
    position: relative;
    background-color: var(--color-muted-background);
    text-align: center;

    .copyright {
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
    }

    .social-links {
        display: inline-flex;
        padding-left: 0;
        list-style: none;
        margin: 10px 0;
        > li {
            margin-left: 10px;
            a {
                padding: 5px;
                display: block;
                width: 30px;
                height: 30px;
                color: var(--color-gray-700);
                svg {
                    max-width: 100%;
                    max-height: 100%;
                    fill: currentColor;
                }
            }
        }
    }

    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg {
            height: 80px;
        }
    }
`;
