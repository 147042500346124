/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useCallback, useEffect } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import { GalleryItem } from './styles';
import clsx from 'clsx';

const LightGallerySection = ({ data, path }) => {
    const lightGallery = useRef(null);

    const onInit = useCallback((detail: any) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);

    useEffect(() => {
        lightGallery.current.refresh();
    }, [data]);
    return (
        <LightGallery plugins={[lgZoom]} onPosterClick={(detail) => detail.instance.refresh()} onInit={onInit}>
            {data.map((d, index) => (
                <GalleryItem
                    className={clsx({
                        hide: index > 5,
                    })}
                    data-src={`assets/room/${path}/jpg-${d}-min.jpg`}
                >
                    <img className='img-responsive' src={`assets/room/${path}/thumb/jpg-${d}-min.jpg`} alt={`macao-hotel-${d}`} />
                </GalleryItem>
            ))}
        </LightGallery>
    );
};
export default LightGallerySection;
