import { useRef, useEffect, useState } from 'react';
import { SectionBackgroun, FullScreenButton, HeroContent, VideoPoster } from './styles';
import heroVideo from './assets/herovideo.mp4';
import { ReactComponent as FullScreenSvg } from '../../icons/fullscreen.svg';
import { useFullPageContext } from '../../Providers/FullPageProvider';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
    const { state } = useFullPageContext();
    const videoRef = useRef();
    const { t } = useTranslation();
    const [posterVisible, setPosterVisible] = useState(true);

    useEffect(() => {
        videoRef.current.play();
        setTimeout(() => {
            setPosterVisible(false);
        }, 10000);
    }, [state]);

    const goFullscreen = () => {
        const video = videoRef.current;
        if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
            video.muted = false;
        } else if (video.webkitRequestFullScreen) {
            video.webkitRequestFullScreen();
            video.muted = true;
        }
    };

    return (
        <section className='section'>
            <HeroContent>
                <h1>{t('hero.title')}</h1>
                <p>{t('hero.description')}</p>
            </HeroContent>
            <SectionBackgroun>
                <VideoPoster $visible={posterVisible} />
                <video loop muted autoPlay playsInline ref={videoRef}>
                    <source src={heroVideo} type='video/mp4' />
                </video>
                <FullScreenButton onClick={goFullscreen}>
                    <FullScreenSvg />
                </FullScreenButton>
            </SectionBackgroun>
        </section>
    );
};

export default HeroSection;
