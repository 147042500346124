const en = {
    common: {
        rooms: 'Rooms',
        aboutus: 'About Us',
        contact: 'Contact',
    },
    navigation: {
        home: 'Home',
        rooms: 'Rooms',
        about: 'About us',
        contact: 'Contact',
    },
    hero: {
        title: 'Welcome to Macao Hotel',
        description: 'The hotel is located at Bagrevand 88/6, Nor Nork district, just 10 km from the city center',
    },
    about: {
        title: 'About us',
        description: `Built with modern design solutions, our newly opened hotel opens its welcoming doors to all guests. It is a wonderful place to stay for tourists coming to Armenia, as it is located close to the tourist destinations of Tsaghkadzor and Garni.
        Unique, delicious and fresh dishes are served in the hotel.
        The hotel consists of fourteen elegant rooms, each of which has a unique design.
        Hotel Macao's comfort, thoughtful details and personal service are the key to a warm and family atmosphere.
        Bright and elegant... These are the words to describe our hall, designed for ceremonies and warm family gatherings for up to 50 people. Parking is available.
        `,
    },
    contact: {
        address: 'Bagrevand 88/6, Yerevan  0061, Armenia',
    },
    rooms: {
        size: 'sq.m each',
        bathroom: 'Bathroom with accessories',
        miniBar: 'Mini-bar',
        wifi: 'Free Wi-Fi',
        tv: 'TV set with cable channels',
        air: 'Air conditioning and heating',
        seating: 'A seating area',
        food: 'Breakfast',
        standart: 'Standard',
        delux: 'Executive Suite',
        luxe: 'Luxe',
        family: 'Family',
    },
};

export default en;
