import styled from 'styled-components';

export const NavElement = styled.nav`
    display: inline-flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
        display: none;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-left: -10px;
        margin-right: -10px;
        li {
            display: inline-block;
            margin: 0 10px;
        }
        > li a {
            padding: 10px;
            font-size: 14px;
            display: block;
            text-decoration: none;
            cursor: pointer;
            font-weight: bold;
            transition: all 0.1s ease;
            position: relative;
            text-transform: uppercase;
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 10px;
                right: 10px;
                height: 1px;
                background-color: var(--color-primary);
                opacity: 0;
                transition: all 0.1s ease;
            }
            &.active,
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
`;
