import styled from 'styled-components';

export const Wrapper = styled.section`
    text-align: center;

    .content {
        display: inline-block;
        margin: auto;
        @media only screen and (max-width: 767px) {
            width: 98%;
            .section-title {
                font-size: 18px;
            }
        }
    }
    .section-title {
        display: inline-block;
        text-align: center;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 20px;
        display: block;
        li {
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--color-text);
            a {
                color: var(--color-text);
                text-decoration: none;
                & + a {
                    margin-left: 10px;
                }
            }
            svg {
                width: 20px;
                margin-right: 5px;
            }
        }
        @media only screen and (max-width: 767px) {
            li {
                text-align: left;
                font-size: 15px;
                span {
                    margin-left: 10px;
                }

                a {
                    font-size: 15px;
                }
            }
        }
    }
`;
