import styled from 'styled-components';

export const Wrapper = styled.section``;

export const Content = styled.div`
    padding-top: 120px;
    text-align: center;
    height: 100%;

    .room-accessories-list {
        list-style: none;
        padding-left: 0;
        text-align: center;
        padding: 40px;
        > li {
            display: inline-block;
            text-align: center;
            vertical-align: top;
            width: 98px;
            margin: 20px 0;
            line-height: normal;

            img {
                height: 32px;
                width: 32px;
                display: block;
                margin: auto;
            }
            span {
                margin: 10px auto 0 auto;
                max-width: 130px;
                text-align: center;
                line-height: normal;
                font-size: 11px;
            }
        }
    }

    .room-slider {
        text-align: left;
    }
`;

export const RoomButtons = styled.div`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const RoomButton = styled.button`
    cursor: pointer;
    position: relative;
    min-width: 150px;
    display: inline-block;
    padding: 8px 20px;
    color: var(--color-text);
    border: 2px solid var(--color-muted);
    background-color: transparent;
    border-radius: 6px;
    transform: scale(1);
    transition: all 0.1s ease-in;
    span {
        position: relative;
        z-index: 1;
    }
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-muted-background);
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
    }
    & + button {
        margin-left: 20px;
    }
    &:active {
        transform: scale(0.98);
    }
    &:hover,
    &.active {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
`;
