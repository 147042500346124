import { useEffect } from 'react';
import { Wrapper, Content } from './styles';
import { useTranslation } from 'react-i18next';
import macaoFront from '../../assets/images/macaoFront.jpeg';
import macaoFront1 from '../../assets/images/macaoFront1.jpeg';
import macaoInner from '../../assets/images/macaoInner.jpeg';
import macaoInner1 from '../../assets/images/macaoInner1.jpeg';
import { useFullPageContext } from '../../Providers/FullPageProvider';

let g_interval;
const AboutSection = ({ activeIndex }) => {
    const { t } = useTranslation();
    const { fullpageApi } = useFullPageContext();

    useEffect(() => {
        if (activeIndex === 2) {
            clearInterval(g_interval);

            const lapse = 3000;
            g_interval = setInterval(function () {
                fullpageApi.moveSlideRight();
            }, lapse);
        } else {
            clearInterval(g_interval);
        }
    }, [activeIndex, fullpageApi]);

    return (
        <Wrapper className='section'>
            <div className='slide' style={{ backgroundImage: `url('${macaoFront}')` }}></div>
            <div className='slide' style={{ backgroundImage: `url('${macaoFront1}')` }}></div>
            <div className='slide' style={{ backgroundImage: `url('${macaoInner}')` }}></div>
            <div className='slide' style={{ backgroundImage: `url('${macaoInner1}')` }}></div>
            <Content>
                <h2 className='section-title'>{t('about.title')}</h2>
                <p>{t('about.description')}</p>
            </Content>
            <div className='section-overlay'></div>
        </Wrapper>
    );
};

export default AboutSection;
