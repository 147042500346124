import { createContext, useContext } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

export const FullPageContext = createContext();

const FullPageProvider = ({ children, fpProps = {} }) => {
    return (
        <ReactFullpage
            easing='easeInOutCubic'
            easingcss3='ease'
            slidesNavigation
            slidesNavPosition='bottom'
            licenseKey={process.env.REACT_APP_FULLPAGE_KEY}
            render={({ state, fullpageApi }) => {
                return <FullPageContext.Provider value={{ state, fullpageApi }}>{children}</FullPageContext.Provider>;
            }}
            {...fpProps}
        />
    );
};

function useFullPageContext() {
    const context = useContext(FullPageContext);

    if (typeof context === 'undefined') {
        throw new Error('useFullPageContext must be used within a FullPageProvider');
    }

    return context;
}

export { useFullPageContext };
export default FullPageProvider;
