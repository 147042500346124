import styled from 'styled-components';

export const GalleryItem = styled.a`
    width: ${(props) => `calc(${100 / 3}% - 20px)`};
    display: inline-block;
    margin: 10px;
    &.hide {
        display: none !important;
    }
    > img {
        width: 100%;
    }
`;
