import { FooterWrapper } from './styles';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as FacebbokIcon } from '../../icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg';
import { ReactComponent as TripAdvisorIcon } from '../../icons/tripadvisor.svg';

const Footer = () => {
    return (
        <FooterWrapper id='pageFooter'>
            <div className='logo-wrapper'>
                <Logo />
                MACAO HOTEL
            </div>
            <ul className='social-links'>
                <li>
                    <a href=''>
                        <TripAdvisorIcon />
                    </a>
                </li>
                <li>
                    <a href='https://www.facebook.com/MacaoHotelYerevan' target='_blank' rel='noreferrer'>
                        <FacebbokIcon />
                    </a>
                </li>
                <li>
                    <a href='https://www.instagram.com/macao_hotel/' target='_blank' rel='noreferrer'>
                        <InstagramIcon />
                    </a>
                </li>
            </ul>
            <p className='copyright'>© {new Date().getFullYear()} | Macao Hotel Yerevan</p>
        </FooterWrapper>
    );
};

export default Footer;
