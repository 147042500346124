import { useTranslation } from 'react-i18next';
import { Wrapper, Content, RoomButtons, RoomButton } from './styles';
import { useMemo, useState } from 'react';
import LightGallerySection from './LighGallery';
import clsx from 'clsx';
import sizeIcon from '../../assets/images/rooms/size.png';
import bathroomIcon from '../../assets/images/rooms/fen.png';
import miniBarIcon from '../../assets/images/rooms/bar.png';
import wifiIcon from '../../assets/images/rooms/wifi.png';
import tvIcon from '../../assets/images/rooms/tv.png';
import airIcon from '../../assets/images/rooms/ac.png';
import seatingIcon from '../../assets/images/rooms/chair.png';
import foodIcon from '../../assets/images/rooms/food.png';

const icons = {
    size: sizeIcon,
    bathroom: bathroomIcon,
    miniBar: miniBarIcon,
    wifi: wifiIcon,
    tv: tvIcon,
    air: airIcon,
    seating: seatingIcon,
    food: foodIcon,
};

const RoomSection = () => {
    const [activeRoomId, setActiveRoomId] = useState('standart');
    const { t } = useTranslation();

    const rooms = useMemo(() => {
        return [
            {
                id: 'standart',
                name: t('rooms.standart'),
                path: 'standart',
                images: [1, 2, 3, 4],
                accessories: {
                    size: 20,
                    bathroom: true,
                    miniBar: true,
                    wifi: true,
                    tv: true,
                    air: true,
                    seating: true,
                    food: true,
                },
            },
            {
                id: 'room-3',
                name: t('rooms.delux'),
                path: 'deluxe',
                images: [1, 2, 3, 4, 5, 6, 7],
                accessories: {
                    size: 30,
                    bathroom: true,
                    miniBar: true,
                    wifi: true,
                    tv: true,
                    air: true,
                    seating: true,
                    food: true,
                },
            },
            {
                id: 'room-2',
                name: t('rooms.luxe'),
                path: 'luxe',
                images: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                accessories: {
                    size: 40,
                    bathroom: true,
                    miniBar: true,
                    wifi: true,
                    tv: true,
                    air: true,
                    seating: true,
                    food: true,
                },
            },
            {
                id: 'room-4',
                name: t('rooms.family'),
                path: 'family',
                images: [1, 2, 3, 4],
                accessories: {
                    size: 80,
                    bathroom: true,
                    miniBar: true,
                    wifi: true,
                    tv: true,
                    air: true,
                    seating: true,
                    food: true,
                },
            },
        ];
    }, [t]);

    const handleClickRoom = (id) => {
        setActiveRoomId(id);
    };

    const activeRoom = useMemo(() => {
        return rooms.find((r) => r.id === activeRoomId);
    }, [activeRoomId, rooms]);

    return (
        <Wrapper className='section'>
            <Content>
                <h2 className='section-title'>{t('common.rooms')}</h2>
                <RoomButtons>
                    {rooms.map((r) => (
                        <RoomButton
                            key={r.id}
                            className={clsx({ active: r.id === activeRoomId })}
                            onClick={() => handleClickRoom(r.id)}
                        >
                            <span>{r.name}</span>
                        </RoomButton>
                    ))}
                </RoomButtons>
                <div className='row'>
                    <div className='col-xs-5'>
                        <h3>{activeRoom.name}</h3>
                        <ul className='room-accessories-list'>
                            {Object.keys(activeRoom.accessories).map((a) => (
                                <li>
                                    <img src={icons[a]} alt={a} />
                                    <span>
                                        {a === 'size' && activeRoom.accessories[a]}
                                        {t(`rooms.${a}`)}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='col-xs-7'>
                        <div className='room-slider'>
                            <LightGallerySection data={activeRoom.images} path={activeRoom.path} />
                        </div>
                    </div>
                </div>
            </Content>
        </Wrapper>
    );
};

export default RoomSection;
