const ru = {
    common: {
        rooms: 'Комнаты',
        aboutus: 'О Нас',
        contact: 'Контакы',
    },
    navigation: {
        home: 'Главная',
        rooms: 'Комнаты',
        about: 'О нас',
        contact: 'Контакы',
    },
    hero: {
        title: 'Добро пожаловать в отель Макао',
        description:
            'Отель расположен по адресу Багреванди 88/6, район Нор Норк, всего в 10 км от центра города. Благодаря этому гость себя чувствуете и в сердце города и загородом',
    },
    about: {
        title: 'О нас',
        description: `Наш недавно открывшийся отель, построенный с использованием современных дизайнерских решений, открывает свои гостеприимные двери для всех гостей. Это прекрасное место для отдыха туристов, приезжающих в Армению, так как оно расположено недалеко от туристических центров Цахкадзор и Гарни.
        В отеле подают уникальные, вкусные и свежие блюда.
        Отель состоит из четырнадцати элегантных номеров, каждый из которых имеет неповторимый дизайн.
        Комфорт отеля Macao, продуманные детали и индивидуальное обслуживание являются ключом к теплой и семейной атмосфере.
        Яркий и элегантный... Именно такими словами можно описать наш зал, предназначенный для торжеств и теплых семейных посиделок до 50 человек.\n
        Доступна парковка
        `,
    },
    contact: {
        address: 'Багреванди 88/6, Ереван 0061, Армения',
    },
    rooms: {
        size: 'кв. м',
        bathroom: 'Ванная комната, принадлежности',
        miniBar: 'Мини-бар',
        wifi: 'Бесплатный Wi-Fi',
        tv: 'Телевизор LCD',
        air: 'Центральное кондиционирование',
        seating: 'Рабочий стол и зона отдыха',
        food: 'Завтрак',
        standart: 'Стандарт',
        delux: 'Представительский Люкс',
        luxe: 'Люкс',
        family: 'Семейная',
    },
};

export default ru;
