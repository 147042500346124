const hy = {
    common: {
        rooms: 'Սենյակներ',
        aboutus: 'About Us',
        contact: 'Գրեք կամ զանգահարեք մեզ`',
    },
    navigation: {
        home: 'Գլխավոր',
        rooms: 'Սենյակներ',
        about: 'Մեր մասին',
        contact: 'Կապ',
    },
    hero: {
        title: 'Բարի Գալուստ Մակաո հյուրանոց',
        description:
            'Հյուրանոցը գտնվում է Նոր Նորք թաղամասի Բագրևանդի 88/6 հասցեում, քաղաքի կենտրոնից ընդամենը 10կմ հեռավորության վրա։Շնորհիվ դրա հյուրը իրեն զգում է  և՛ քաղաքի սրտում, և՛ քաղաքից դուրս',
    },
    about: {
        title: 'Մեր մասին',
        description: `Ժամանակակից դիզայներական լուծումներով կառուցված մեր նորաբաց հյուրանոցն իր հյուրընկալ դռներն է բացում բոլոր հյուրերի առջև: Այն հրաշալի կեցավայր է Հայաստան ժամանած զբոսաշրջիկների համար քանի, որ գտնվում է Ծաղկաձոր, Գառնի, Գեղարդ զբոսաշրջային ուղղությունների ճանապարհին։ Հյուրանոցում մատուցվում են յուրօրինակ, համեղ և թարմ ուտեստներ: Հյուրանոցը բաղկացած է տասնչորս նրբաճաշակ համարներից, որոնցից յուրաքանչյուրն առանձնանում է իր ուրույն դիզայնով։ Macao հյուրանոցի հարմարավետությունը, հատուկ մտածված դետալները,  անհատական սպասարկումը, ջերմ և ընտանեկան միջավայրի գրավականն են։ Լուսավոր և նրբաճաշակ․․․ ահա այս խոսքերով   է կարելի նկարագրել մեր սրահը՝ այն նախատեսված մինչև մինչև 50 հոգանոց արարողությունների և ջերմ ընտանեկան հավաքույթների համար։ Առկա է նաև ավտոկայանատեղի:
        `,
    },
    contact: {
        address: 'Բագրևանդ 88/6, Երևան 0061, Հայաստան',
    },
    rooms: {
        size: 'քմ մակերես',
        bathroom: 'Լոգասենյակ իր պարագաներով',
        miniBar: 'Մինի-բար',
        wifi: 'Անվճար Wi-Fi',
        tv: 'LCD էկրանով հեռուստացույց',
        air: 'Կենտրոնական ջեռուցում',
        seating: 'Աշխատասեղան և բազմոց',
        food: 'Նախաճաշ',
        standart: 'Ստանդարտ',
        delux: 'Դելյուքս',
        luxe: 'Լյուքս',
        family: 'Ընտանեկան',
    },
};

export default hy;
