import styled, { css } from 'styled-components';
import videoPoster from '../../assets/images/macaoFront.jpeg';

export const VideoPoster = styled.div`
    position: absolute;
    inset: 0;
    z-index: 12;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${videoPoster})`};
    transition: all 0.4s ease-in-out;
    ${(props) =>
        props.$visible
            ? css`
                  visibility: visible;
                  opacity: 1;
              `
            : css`
                  visibility: hidden;
                  opacity: 0;
              `}
`;

export const SectionBackgroun = styled.div`
    position: absolute;
    inset: 0;
    overflow: hidden;
    top: -60px;
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 13;
        background-color: var(--color-muted-background);
        opacity: 0.7;
    }
    video {
        position: relative;
        z-index: 11;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        transform: scale(1.2);
    }
`;

export const FullScreenButton = styled.button`
    border: none;
    background: none;
    -webkit-appearance: none;
    padding: 5px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    line-height: 1;
    z-index: 20;
    cursor: pointer;
    color: var(--color-text);
    opacity: 0.6;
    transform: scale(1);
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.3);
    }
`;

export const HeroContent = styled.div`
    position: relative;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 50%;
    margin: auto;
    text-align: center;

    h1 {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    p {
        font-size: 18px;
    }

    @media only screen and (max-width: 767px) {
        max-width: 90%;

        h1 {
            font-size: 22px;
        }
        p {
            font-size: 13px;
        }
    }
`;
